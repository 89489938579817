import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean_matrac.jpg';
import main3 from '../../../img/Services/clean_pillow.jpg';
import main2 from '../../../img/Services/clean_chair.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title main-services">
          <p>Our services</p>
          <p className="main-title-upper">
            <span>For companies and for home</span>
          </p>
          <h1>
            Professional washing of sofas, armchairs, mattresses, strollers,
            curtains, blinds, carpets, car interiors...
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Washing couches</h2>
            <p className="main-services-price">od 100 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Washing armchairs</h2>
            <p className="main-services-price">od 15 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Washing pillows</h2>
            <p className="main-services-price">od 5 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>WASHING MATTRESS</h2>
            <p className="main-services-price">od 100 pln</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/ru/price">More details...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
